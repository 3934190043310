import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Game } from '../components/Game/index';
import Logo from '../components/Logo';

type Props = {
	pageContext: { question: { query: string } };
};

const AnswerPage = ({ pageContext }: Props) => {
	return (
		<>
			<Helmet>
				<title>{`Google Feud - Answers - ${pageContext.question.query}`}</title>
			</Helmet>
			<Logo />
			<Game staticQuery={pageContext.question.query} />
		</>
	);
};
export default AnswerPage;
